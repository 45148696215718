@import '../../styles/partials/variables';

.topic__header {
  background: $color__light-wheat;
  margin-left: -2.5rem;
  width: calc(100% + 5rem);
  padding: calculateRem(70px) 0;
}

.topic__container {
  justify-content: initial;
}

.topic__meta {
  background: #fff;
  padding: calculateRem(49px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @include grid-col(3.5, $grid-columns: 9);
}

.topic__label {
  text-transform: uppercase;
  font-family: 'Paralucent';
  font-weight: 700;
  color: $color__gray-dark;
  margin-bottom: calculateRem(11px);
  position: relative;
  display: inline-block;
  @include convertToRem(20px);

  span {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 11px;
    background: $color__wheat;
  }
}

.topic__name {
  color: $color__green;
  font-family: 'Paralucent';
  font-weight: 900;
  line-height: 1;
  margin-bottom: calculateRem(16px);
  @include convertToRem(44px)
}

.topic__description {
  font-family: 'Roboto';
  line-height: 30px;
  @include convertToRem(18px);
}

.topic__image_wrapper {
  @include grid-col(5.5, $grid-columns: 9);
}

.topic__image {
  max-width: 100%;
}