@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap');
@import url('https://use.typekit.net/rxk0ete.css');

.paralucent {
  font-family: paralucent, sans-serif;
  font-weight: 700;
  font-style: normal;
  &--heavy {
    font-weight: 900;
  }
}

.ibm-plex-sans {
  font-family: 'IBM Plex Sans', sans-serif;
  &--semi-bold {
    font-weight: 600;
  }
}

.roboto {
  font-family: 'Roboto', sans-serif;
  &--bold {
    font-weight: 700;
  }
}

.roboto-mono {
  font-family: 'Roboto Mono', monospace;
}
