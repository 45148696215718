@import '../../styles/partials/variables';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calculateRem(88px);
}
.subtitle {
    @include convertToRem(20px);
    line-height: calculateRem(28px);
    font-weight: $bold-weight;
    font-family: $font__secondary;
    color: $color__gray-black-dark;
    margin-bottom: calculateRem(15px);
}
.title {
  color: $color__gray-dark;
  font-weight: $black-weight;
  @include convertToRem(44px);
  line-height: calculateRem(49px);
  font-family: $font__secondary;
  text-transform: uppercase;
  margin-bottom: calculateRem(80px);
  text-align: center;
}