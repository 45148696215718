@import './mixins';
@import './grid';
@import './colors';
@import './typography';

$site_box_shadow_size: 9px;
$site_box_shadow: $site_box_shadow_size $site_box_shadow_size 0px $color__wheat;

$font__main: 'IBM Plex Sans', sans-serif;
$font__secondary: paralucent, sans-serif;
$font__labels: 'Roboto', sans-serif;
$font__poppins:'Poppins', sans-serif;

$normal-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
$bold-weight: 700;
$xbold-weight: 800;
$black-weight: 900;

$max_width: calculateRem(1157px); // Max width of site
$content_max: calculateRem(626px); // Max width of inner content

$padding_desktop: calculateRem(40px); // Padding on sides of desktop
$padding_tablet: calculateRem(30px); // Padding on sides of tablets
$padding_mobile: calculateRem(20px); // Padding on sides of mobile
$padding_small: calculateRem(12px); // Padding on sides of small phones

$xsmobile: calculateRem(280px); // Min-width mobile
$mobile: calculateRem(375px); // Min-width mobile
$mobile2: calculateRem(615px); // Min-width mobile
$tablet: calculateRem(913px); // Min-width tablet
$desktop: calculateRem(1238px); // Min-width desktop

$breakpoints: (
  'small': (
    min-width: $xsmobile,
  ),
  'mobile': (
    min-width: $mobile,
  ),
  'mobile2': (
    min-width: $mobile2,
  ),
  'mobile3': (
    max-width: $mobile2,
  ),  
  'tablet': (
    min-width: $tablet,
  ),
  'tablet2': (
    max-width: $tablet,
  ),
  'desktop': (
    min-width: $desktop,
  ),
) !default;

$columns_desktop: 9;
$columns_tablet: 9;
$columns_mobile: 4;
$columns_small: 2;

$gap_desktop: 40px;
$gap_tablet: 30px;
$gap_mobile: 20px;
$gap_small: 12px;
