@import '../../styles/partials/colors';
@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.main {
  display: flex;
  align-items: center;
  margin-bottom: calculateRem(16px);

  @include grid-col(2, $grid-columns: 2);

  @include respond-to('mobile') {
    @include grid-col(4, $grid-columns: 4);
  }

  @include respond-to('tablet') {
    @include grid-col(9, $grid-columns: 9);
  }

  @include respond-to('desktop') {
    @include grid-col(9, $grid-columns: 9);
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: $bold-weight;
    color: $color__green;
    line-height: 30px;
    margin-right: calculateRem(12px);
    flex-shrink: 0;
    @include convertToRem(20px);
  }

  &::after {
    content: '';
    width: 100%;
    flex: 1;
    height: 3px;
    display: block;
    background: $color__green;
  }
}