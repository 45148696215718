@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.mainWrapper {
  padding: 0 calculateRem(120px);
  @include respond-to('mobile') {
    padding: 0 0;
  }  
}

.post {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: calculateRem(44px);
  flex-wrap: wrap;

  @include respond-to('tablet') {
    // flex-direction: row;
  }
}

.loadMoreWrapper {
  text-align: center;
  margin-top: calculateRem(50px);
  margin-bottom: calculateRem(50px);

  .loadMore {
    text-decoration: none;
    background-color: $color__green;
    color: $color__background;
    border-radius: 22px;
    line-height: calculateRem(20px);
    font-family: $font__secondary;
    width: calculateRem(127px);
    height: calculateRem(43px);
    padding: calculateRem(13px) calculateRem(23px);
    @include convertToRem(17px);

    &:hover {
      background-color: $color__gray-black-dark;
    }
  }
}

.mediaWrapper {
  padding-left: $gap_mobile;

  @include respond-to('tablet') {
    padding-left: $gap_tablet;
  }

  @include respond-to('desktop') {
    padding-left: $gap_desktop;
  }
}
