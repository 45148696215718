@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Poppins:wght@300;700;800&family=Roboto:wght@400;900&family=Roboto+Mono:wght@400;700&display=swap);
@import url(https://use.typekit.net/rxk0ete.css);
.BlockPostTitle_paralucent__2gMmV{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.BlockPostTitle_paralucent--heavy__cpugm{font-weight:900}.BlockPostTitle_ibm-plex-sans__20scH{font-family:"IBM Plex Sans",sans-serif}.BlockPostTitle_ibm-plex-sans--semi-bold__zDOi1{font-weight:600}.BlockPostTitle_roboto__3rfWY{font-family:"Roboto",sans-serif}.BlockPostTitle_roboto--bold__3VZCY{font-weight:700}.BlockPostTitle_roboto-mono__2VFGF{font-family:"Roboto Mono",monospace}.BlockPostTitle_main__2qEag{display:flex;align-items:center;margin-bottom:1rem;box-sizing:border-box;flex:0 0 100%}@media(min-width: 23.4375rem){.BlockPostTitle_main__2qEag{box-sizing:border-box;flex:0 0 100%}}@media(min-width: 57.0625rem){.BlockPostTitle_main__2qEag{box-sizing:border-box;flex:0 0 100%}}@media(min-width: 77.375rem){.BlockPostTitle_main__2qEag{box-sizing:border-box;flex:0 0 100%}}.BlockPostTitle_main__2qEag h2{font-family:"Poppins",sans-serif;font-weight:700;color:#1b4d48;line-height:30px;margin-right:.75rem;flex-shrink:0;font-size:20px;font-size:1.25rem}.BlockPostTitle_main__2qEag::after{content:"";width:100%;flex:1 1;height:3px;display:block;background:#1b4d48}
.BlockTagTitle_paralucent__1M23I{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.BlockTagTitle_paralucent--heavy__2_ira{font-weight:900}.BlockTagTitle_ibm-plex-sans__27VjV{font-family:"IBM Plex Sans",sans-serif}.BlockTagTitle_ibm-plex-sans--semi-bold__2o76Y{font-weight:600}.BlockTagTitle_roboto__3BZAX{font-family:"Roboto",sans-serif}.BlockTagTitle_roboto--bold__1E-e3{font-weight:700}.BlockTagTitle_roboto-mono__1WpWK{font-family:"Roboto Mono",monospace}.BlockTagTitle_main__lIGTA{display:flex;flex-direction:column;align-items:center;margin-top:5.5rem}.BlockTagTitle_subtitle__1BB9q{font-size:20px;font-size:1.25rem;line-height:1.75rem;font-weight:700;font-family:paralucent,sans-serif;color:#312b2b;margin-bottom:.9375rem}.BlockTagTitle_title__16m0N{color:#4b4a4a;font-weight:900;font-size:44px;font-size:2.75rem;line-height:3.0625rem;font-family:paralucent,sans-serif;text-transform:uppercase;margin-bottom:5rem;text-align:center}
.BlockMediaCard_paralucent__49trs{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.BlockMediaCard_paralucent--heavy__34dDn{font-weight:900}.BlockMediaCard_ibm-plex-sans__1TLiO{font-family:"IBM Plex Sans",sans-serif}.BlockMediaCard_ibm-plex-sans--semi-bold__3co_Z{font-weight:600}.BlockMediaCard_roboto__3QcqV{font-family:"Roboto",sans-serif}.BlockMediaCard_roboto--bold__3nxHC{font-weight:700}.BlockMediaCard_roboto-mono__1eUh0{font-family:"Roboto Mono",monospace}.BlockMediaCard_postcard__3KvSV{display:flex;flex-direction:column;margin-top:1.625rem}.BlockMediaCard_postcard__3KvSV a{text-decoration:none}.BlockMediaCard_rowcard__nGZCt{display:flex;flex-direction:column}.BlockMediaCard_image__3IFgd{height:6.5rem;width:11.5625rem;padding:.3125rem}@media(min-width: 23.4375rem){.BlockMediaCard_image__3IFgd{height:5.75rem;width:10.25rem}}.BlockMediaCard_novideo__BUijZ{display:flex;flex-direction:row;align-items:center}.BlockMediaCard_rowitems__2A9Bj{display:flex;flex-direction:column;margin-left:.125rem;margin-top:.3125rem}.BlockMediaCard_subheader__1g_v3{color:#312b2b;font-size:12px;font-size:.75rem;margin-bottom:.5625rem;letter-spacing:.051875rem}.BlockMediaCard_title__HsN9J{color:#1b4d48;font-size:1.25rem;margin-bottom:.5625rem;width:9.0625rem}@media(min-width: 23.4375rem){.BlockMediaCard_title__HsN9J{width:18.75rem}}.BlockMediaCard_subtitle__xdNzH{color:#343434;font-size:12px;font-size:.75rem}.BlockMediaCard_underline__wo-_h{width:18.75rem;border:1px solid #e2e2e0;margin-top:1.5625rem}
.Button_paralucent__2aGbe{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.Button_paralucent--heavy__1jJWi{font-weight:900}.Button_ibm-plex-sans__2a6uB{font-family:"IBM Plex Sans",sans-serif}.Button_ibm-plex-sans--semi-bold__2a6R3{font-weight:600}.Button_roboto__3tX4w{font-family:"Roboto",sans-serif}.Button_roboto--bold__2lWLc{font-weight:700}.Button_roboto-mono__2e_uk{font-family:"Roboto Mono",monospace}.Button_button__primary__30OWr{background-color:#1b4d48;border-radius:500px;color:#fff;line-height:1.14;font-family:"Paralucent",sans-serif;font-weight:700;padding:.8125rem 1.4375rem;border:2px solid #1b4d48;margin-bottom:7.625rem;margin-left:auto;margin-right:auto;display:block;cursor:pointer;font-size:17px;font-size:1.0625rem}.Button_button__primary__30OWr:hover{background-color:#fcfbf9;color:#1b4d48}
.BlockPost_paralucent__ocx9i{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.BlockPost_paralucent--heavy__2DKk6{font-weight:900}.BlockPost_ibm-plex-sans__2U3rX{font-family:"IBM Plex Sans",sans-serif}.BlockPost_ibm-plex-sans--semi-bold__2rKyz{font-weight:600}.BlockPost_roboto__uK4wB{font-family:"Roboto",sans-serif}.BlockPost_roboto--bold__2-9rc{font-weight:700}.BlockPost_roboto-mono__17jPt{font-family:"Roboto Mono",monospace}.BlockPost_mainWrapper__2jNy1{padding:0 7.5rem}@media(min-width: 23.4375rem){.BlockPost_mainWrapper__2jNy1{padding:0 0}}.BlockPost_post__19bA9{display:flex;justify-content:center;margin:0 auto;margin-top:2.75rem;flex-wrap:wrap}.BlockPost_loadMoreWrapper__2MeLe{text-align:center;margin-top:3.125rem;margin-bottom:3.125rem}.BlockPost_loadMoreWrapper__2MeLe .BlockPost_loadMore__29Bbh{text-decoration:none;background-color:#1b4d48;color:#fcfbf9;border-radius:22px;line-height:1.25rem;font-family:paralucent,sans-serif;width:7.9375rem;height:2.6875rem;padding:.8125rem 1.4375rem;font-size:17px;font-size:1.0625rem}.BlockPost_loadMoreWrapper__2MeLe .BlockPost_loadMore__29Bbh:hover{background-color:#312b2b}.BlockPost_mediaWrapper__2dWQ9{padding-left:20px}@media(min-width: 57.0625rem){.BlockPost_mediaWrapper__2dWQ9{padding-left:30px}}@media(min-width: 77.375rem){.BlockPost_mediaWrapper__2dWQ9{padding-left:40px}}
.TopicHeader_paralucent__3hO2v{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.TopicHeader_paralucent--heavy__3KPjF{font-weight:900}.TopicHeader_ibm-plex-sans__1ja2F{font-family:"IBM Plex Sans",sans-serif}.TopicHeader_ibm-plex-sans--semi-bold__1B0we{font-weight:600}.TopicHeader_roboto__38bbv{font-family:"Roboto",sans-serif}.TopicHeader_roboto--bold__1ZWXS{font-weight:700}.TopicHeader_roboto-mono__2LD3i{font-family:"Roboto Mono",monospace}.TopicHeader_topic__header__2H8si{background:#fcf5eb;margin-left:-2.5rem;width:calc(100% + 5rem);padding:4.375rem 0}.TopicHeader_topic__container__1wUHr{justify-content:initial}.TopicHeader_topic__meta__3h76D{background:#fff;padding:3.0625rem;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;box-sizing:border-box;flex:0 0 38.8888888889%}.TopicHeader_topic__label__1Cefb{text-transform:uppercase;font-family:"Paralucent";font-weight:700;color:#4b4a4a;margin-bottom:.6875rem;position:relative;display:inline-block;font-size:20px;font-size:1.25rem}.TopicHeader_topic__label__1Cefb span{position:relative;z-index:2}.TopicHeader_topic__label__1Cefb::after{content:"";display:block;position:absolute;z-index:1;bottom:-2px;left:0;width:calc(100% + 20px);margin-left:-10px;height:11px;background:#f7debc}.TopicHeader_topic__name__AxusL{color:#1b4d48;font-family:"Paralucent";font-weight:900;line-height:1;margin-bottom:1rem;font-size:44px;font-size:2.75rem}.TopicHeader_topic__description__1RGB3{font-family:"Roboto";line-height:30px;font-size:18px;font-size:1.125rem}.TopicHeader_topic__image_wrapper__20xgH{box-sizing:border-box;flex:0 0 61.1111111111%}.TopicHeader_topic__image__2yVp3{max-width:100%}
.FeaturedTopic_paralucent__2ftcd{font-family:paralucent,sans-serif;font-weight:700;font-style:normal}.FeaturedTopic_paralucent--heavy__2GMw_{font-weight:900}.FeaturedTopic_ibm-plex-sans__KCeCl{font-family:"IBM Plex Sans",sans-serif}.FeaturedTopic_ibm-plex-sans--semi-bold__30ZEq{font-weight:600}.FeaturedTopic_roboto__1a0bn{font-family:"Roboto",sans-serif}.FeaturedTopic_roboto--bold__3XZcT{font-weight:700}.FeaturedTopic_roboto-mono__21xDf{font-family:"Roboto Mono",monospace}.FeaturedTopic_article__cards_title__3GeqQ{display:flex;align-items:center;margin-top:3.5625rem;margin-bottom:1rem;box-sizing:border-box;flex:0 0 100%}@media(min-width: 23.4375rem){.FeaturedTopic_article__cards_title__3GeqQ{box-sizing:border-box;flex:0 0 100%}}@media(min-width: 57.0625rem){.FeaturedTopic_article__cards_title__3GeqQ{box-sizing:border-box;flex:0 0 100%}}@media(min-width: 77.375rem){.FeaturedTopic_article__cards_title__3GeqQ{box-sizing:border-box;flex:0 0 100%}}.FeaturedTopic_article__cards_title__3GeqQ h2{font-family:"Poppins",sans-serif;font-weight:700;color:#1b4d48;line-height:30px;margin-right:.75rem;flex-shrink:0;font-size:20px;font-size:1.25rem}.FeaturedTopic_article__cards_title__3GeqQ::after{content:"";width:100%;flex:1 1;height:3px;display:block;background:#1b4d48}
