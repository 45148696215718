@use 'sass:math';

// reset <button>
@mixin reset-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  @return math.div($size, 16px) * 1rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin convertToRem($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Adobe XD Spacing to actual css values
@mixin letter-spacing($spacing) {
  letter-spacing: math.div($spacing, 1000) * 1em;
}

// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin container($includeRelative: true, $includeCenter: true) {
  max-width: $max-width;
  margin: 0 auto;
  @if $includeRelative {
    position: relative;
  }
  @if $includeCenter {
    justify-content: center;
  }
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
  width: map-get($columns, $numberColumns) - (($columns__margin * ($numberColumns - 1)) / $numberColumns);
}

@mixin column-offset($numberColumns: 3) {
  margin-left: map-get($columns, $numberColumns);
}

@function url-friendly-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

@mixin full-width() {
  margin-left: -#{$size__content-padding-phone};
  margin-right: -#{$size__content-padding-phone};

  @include respond-to($tablet) {
    margin-left: -#{$padding-tablet};
    margin-right: -#{$padding-tablet};
  }

  @media screen and (max-width: ($max-width - 1)) {
    margin-left: -#{$padding-desktop};
    margin-right: -#{$padding-desktop};
  }

  @include respond-to($desktop) {
    margin-left: calc(-100vw / 2 + #{$max-width} / 2 - #{$padding-desktop});
    margin-right: calc(-100vw / 2 + #{$max-width} / 2 - #{$padding-desktop});
  }
}
