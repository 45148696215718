$color__green: #1b4d48;
$color__light-yellow: #f6f5f3;
$color__wheat: #f7debc;
$color__light-wheat: #fcf5eb;
$color__rule-lines: #e2e2e0;
$color__background: #fcfbf9;
$color__logo-green: #1d4d48;
$color__logo-grey: #8a8b8a;
$color__gray-black-dark: #312b2b;
$color__gray-black: #343434;
$color__gray-dark: #4b4a4a;
$color__gray-light: #6e6767;
$color__gray-blue: #4b5758;
$color__red: #990101;
$color_light-gray: #f2f1ef;
