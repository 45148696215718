@import '../../styles/partials/colors';
@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';

.postcard {
  display: flex;
  flex-direction: column;
  margin-top: calculateRem(26px);

  a {
    text-decoration: none;
  }
}

.rowcard {
  display: flex;
  flex-direction: column;
}

.image {
  height: calculateRem(104px);
  width: calculateRem(185px);
  padding: calculateRem(5px);

  @include respond-to('mobile') {
    height: calculateRem(92px);
    width: calculateRem(164px);
  }
}

.novideo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowitems {
  display: flex;
  flex-direction: column;
  margin-left: calculateRem(2px);
  margin-top: calculateRem(5px);
}

.subheader {
  color: $color__gray-black-dark;
  @include convertToRem(12px);
  margin-bottom: calculateRem(9px);
  letter-spacing: calculateRem(0.83px);
}

.title {
  color: $color__green;
  font-size: calculateRem(20px);
  margin-bottom: calculateRem(9px);
  width: calculateRem(145px);

  @include respond-to('mobile') {
    width: calculateRem(300px);
  }
}

.subtitle {
  color: $color__gray-black;
  @include convertToRem(12px);
}

.underline {
  width: calculateRem(300px);
  border: 1px solid $color__rule-lines;
  margin-top: calculateRem(25px);
}
